<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>内容管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path:'/userVideoList' }">用户视频</el-breadcrumb-item>
            <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form label-width="150px" style="width:60%;" :disabled="true">
            <el-form-item label="用户ID">
                <el-input v-model="form.cust_id"></el-input>
            </el-form-item>
            <el-form-item label="标题">
                <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="简介">
                <el-input v-model="form.des" type='textarea' :rows="6"></el-input>
            </el-form-item>
            <el-form-item label="视频封面" style="text-align: left;">
                <el-image style="width: 200px; height: 200px" :src="form.video_pic" :preview-src-list="srcList">
                </el-image>
            </el-form-item>
            <el-form-item label="视频地址" style="text-align: left;">
                <video style="width: 500px;height: 500px;" :src="form.url" controls></video>
            </el-form-item>
        </el-form>
        <el-button @click="$router.push('/userVideoList')">返回</el-button>
    </div>
</template>
<script>
    import upload from '../components/upload'
    export default {
        components: {
            upload
        },
        data() {
            return {
                id: '',
                title: '',
                form: '',
                srcList: []
            }
        },
        mounted() {
            this.id = this.$route.query.id;
            this.title = this.$route.query.title;
            if (this.id) {
                this.getDetail();
            }
        },
        methods: {
            getDetail() {
                this.axios.get('/video/get_gu_video_queue_by_id?id=' + this.id)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.form = res.data.result;
                            this.radio = this.form.is_add + '';
                            this.srcList.push(res.data.result.video_pic);
                        }
                    })
            },
        }
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }
</style>